<template>
  <div>
    <header id="header">
      <div class="header-inner">
        <router-link to="/iseya/home/" class="logo"><img alt="" src="../../assets/logo@2x.png" /></router-link>
        <div class="header-name">有限会社 イセヤ</div>
      </div>
    </header>
    <fixedMenu />
  </div>
</template>

<script>
import fixedMenu from "@/components/iseya/fixedMenu";
export default {
  name: "iseyaHeader",
  components: {
    fixedMenu
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#header {
  width: 100%;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 10px;
}
.logo {
  width: 110px;
}
.header-name {
  position: relative;
  width: 160px;
  height: 100%;
    font-size: 1.6rem;
  transform: scale(0.75);
  color: variables.$txt-default;
  display: flex;
  align-items: center;
}

</style>
